import { graphql } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import { bgOverlayColorMapper } from '../components/artistTile/overlayColorMapper';
import EventContent from '../components/event/eventContent';
import media from '../constants/media';
import IEventPageItem from '../interfaces/eventPageItem/eventPageItem';

interface Props {
	data: {
		prismicEvent: IEventPageItem;
	};
}

const StyledEvent = styled.div`
	padding-top: 1rem;
	padding-bottom: 10rem;

	${media.desktopSmall`
		padding-top: 1rem;
		padding-bottom: 20rem;
	`};
`;

const Event: FC<Props> = ({ data: { prismicEvent } }) => {
	return (
		<StyledEvent>
			<EventContent event={prismicEvent} />
		</StyledEvent>
	);
};

export default Event;

export const pageQuery = graphql`
	query EventBySlug($uid: String!) {
		prismicEvent(uid: { eq: $uid }, data: { title: { text: { ne: "Ignored-dummy-event" } } }) {
			uid
			data {
				title {
					text
				}
				desktop_background {
					url
				}
				tablet_background {
					url
				}
				mobile_background {
					url
				}
				content_box_1 {
					html
				}
				content_box_2 {
					html
				}
				content_box_3 {
					html
				}
				image {
					url
					alt
					mobile {
						url
					}
					tablet {
						url
					}
					desktop {
						url
					}
					retina {
						url
					}
				}
				summary {
					html
				}
				main_text {
					html
				}
				video {
					url
				}
				post_event_text {
					html
				}
				images {
					gallery_image {
						url
						alt
						thumbnail {
							url
						}
					}
				}
			}
		}
	}
`;

import styled from 'styled-components';
import media from '../../../constants/media';

export const Wrapper = styled.div`

	background-size: contain;
	background-repeat: no-repeat;
	background-position: center top;

	${({ mobile }) => mobile && (
		media.mobileOnly`
		background-image: url(${mobile});
	`
	)}
	
	${({ tablet }) => tablet && (
		media.tablet`
		background-image: url(${tablet});
	`
	)}

	${({ desktop }) => desktop && (
		media.desktopSmall`
		background-image: url(${desktop});
	`
	)}
	


	margin: 5rem 0 auto;
	padding: 0 2rem;

	[name="Column"]{
		min-width: 325px;
		margin-bottom: 0px;
	}
	
	[name="Column"]:nth-child(1) { 
		padding-top: 25px;
		order: 1;
		${media.mobileOnly`
			order: 2;
		`};
	}

	[name="Column"]:nth-child(2) { 
		order: 2;
		${media.mobileOnly`
			order: 1;
		`};
	}

	[name="Column"]:nth-child(3) { 
		padding-top: 200px;
		order: 3;
		${media.mobileOnly`
			order: 3;
			padding-top: 25px;
		`};
		${media.tabletOnly`
			order: 3;
			padding-top: 25px;
		`};
	}
`;

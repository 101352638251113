import Interweave from 'interweave';
import React, { FC, useState } from 'react';
import useOnMount from '../../../hooks/useOnMount';
import IEventPageItem from '../../../interfaces/eventPageItem/eventPageItem';
import { Column, Row } from '../../grid/grid';
import { Wrapper } from './eventContent.styles';

interface Props {
	event: IEventPageItem;
}

const EventContent: FC<Props> = ({ event }) => {
	// Interweave uses the document object which breaks the code on build time
	const [mounted, setMounted] = useState(false);
	useOnMount(() => setMounted(true));

	return (
		<Wrapper
			desktop={event.data.desktop_background.url}
			tablet={event.data.tablet_background.url}
			mobile={event.data.mobile_background.url}>
			<Row>
				<Column flex="1">{mounted && <Interweave content={event.data.content_box_1.html} />}</Column>
				<Column flex="1">{mounted && <Interweave content={event.data.content_box_2.html} />}</Column>
				<Column flex="1">{mounted && <Interweave content={event.data.content_box_3.html} />}</Column>
			</Row>
		</Wrapper>
	);
};

export default EventContent;
